import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";
import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { setCredentials } from "../../../reducers/authSlice";
import {
  useLazyGetChallengerListQuery
} from "../../../services/main";
import { Challenger } from "../../../types/General";
import Loader from "../../../constants/Loader";

export default function ChallengerList() {
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getChallengerListMutation, { isLoading }] = useLazyGetChallengerListQuery();
  const [page, setPage] = useState(1);
  const [ChallengerData, setChallengerData] = useState<Challenger[]>([]);
  const [sortSelect, setSortSelect] = useState("1");
  const [positionSelect, setPositionSelect] = useState("2");
  const [selectedCate, setSelectCate] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const getChallengerList = async (id: string) => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    id = id || 'all';
    try {
      const res = await getChallengerListMutation({ id }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        console.log(res);
        setChallengerData(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChallengerList('all');
  }, [
    debouncedSearchTerm,
    page,
    positionSelect,
    sortSelect,
    selectedCate,
    value,
  ]);
  return (
    <>
      <Loader isLoad={isLoading || loading} />
      <div className="rpt_card">
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>UserId</TableCell>
                <TableCell>Sponsor Name</TableCell>
                <TableCell>Total NFTs</TableCell>
                <TableCell>Total Weight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ChallengerData?.length
                ? ChallengerData?.map((item, i) => (
                  <TableRow style={{ cursor: "pointer" }}>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>{item?.sponsorName}</TableCell>
                    <TableCell>{item?.totalNFTs}</TableCell>
                    <TableCell>{item?.totalWeight}</TableCell>
                  </TableRow>
                ))
                : !loading &&
                !ChallengerData?.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

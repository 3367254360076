import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetPlatformNftByIdQuery } from "../../services/main";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { Nft } from "../../types/General";
import Loader from "../../constants/Loader";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";

export default function EditPlatformNft() {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getNftByIdMutation, { isLoading }] = useLazyGetPlatformNftByIdQuery();
  const [nftData, setNftData] = useState<Nft | null>(null);

  const getNftById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const response = await getNftByIdMutation({ id }).unwrap();
      if (response?.statusCode === 200) {
        setNftData(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getNftById(id);
    }
  }, []);

  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading} />
      <div className="manage_blog view_nft">
        <div className="sec_head fs_22">
          <h2>View NFT</h2>
          <div className="btn_group">
            <button
              className="btn btn_sm btn_primary"
              onClick={() => navigate("/manage-platform-nft")}
            >
              Back
            </button>
          </div>
        </div>

        <div className="form_title fs_16">
          <h2>NFT Detail</h2>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>NFT Image</p>
              <div className="prfl_pic w_30">
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <img src={nftData?.image || ""} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>NFT Name</p>
              <h6>{nftData?.name || ""}</h6>
            </li>
            <li>
              <p>Brand Name</p>
              <h6>{nftData?.brandName || ""}</h6>
            </li>
            <li>
              <p>Channel Name</p>
              <h6>
                {nftData?.channelName === 1
                  ? "Facebook"
                  : nftData?.channelName === 2
                    ? "Telegram"
                    : nftData?.channelName === 3
                      ? "Twitter"
                      : nftData?.channelName === 4
                        ? "LinkedIn"
                        : nftData?.channelName === 5
                          ? "WhatsApp"
                          : "WhatsApp"}
              </h6>
            </li>
            <li>
              <p>I2E Points</p>
              <h6>{nftData?.basePrice || "0"}</h6>
            </li>
            <li>
              <p>Carbon Credit</p>
              <h6>{nftData?.carbonCredit || "0"}</h6>
            </li>
            <li>
              <p>NFT Weight (Grams)</p>
              <h6>{nftData?.weight || ''}</h6>
            </li>
            <li>
              <p>Number Of Items</p>
              <h6>{nftData?.quantity || ''}</h6>
            </li>
            <li>
              <p>Collection Center</p>
              <h6>{nftData?.collectionPointData?.name || ""}</h6>
            </li>
            <li>
              <p>Status</p>
              <h6>
                {nftData?.status === 1
                  ? "Draft"
                  : nftData?.status === 4
                    ? "Declined"
                    : nftData?.status === 3
                      ? "Accepted"
                      : "Verification Required"}
              </h6>
            </li>
            {/* <li>
              <p>Audio Url</p>
              <h6>{nftData?.audio || "0"}</h6>
            </li> */}
            <li className="w_100">
              <p>Description</p>
              <h6>{nftData?.description || ""}</h6>
            </li>
          </ul>
        </div>

        <div className="form_title fs_16 mt_40">
          <h2>User Detail</h2>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li>
              <p>Name</p>
              <h6>{nftData?.user?.name || ""}</h6>
            </li>
            {/* <li>
              <p>Contact Number</p>
              <h6>9877454492</h6>
            </li> */}
            <li>
              <p>Email</p>
              <h6>{nftData?.user?.email || ""}</h6>
            </li>
            <li>
              <p>Social Id</p>
              <h6>{nftData?.user?.socialMediaId || ""}</h6>
            </li>
          </ul>
        </div>
      </div>
    </MainContainer>
  );
}

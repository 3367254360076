import React, { useEffect, useState } from "react";

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import MainContainer from "../../../layout/MainContainer";
import LineChart from "../../../components/linechart";
import {
  useLazyCouponsClaimedGraphQuery,
  useLazyTotalNftGraphQuery,
  useLazyTotalUsersGraphQuery,
} from "../../../services/common";
import LogRenderer from "../../dashboard/feature/logRenderer";

const Analytics = () => {
  const [nftMutation] = useLazyTotalNftGraphQuery();
  const [userMutation] = useLazyTotalUsersGraphQuery();
  const [couponMutation] = useLazyCouponsClaimedGraphQuery();

  const [nftFilter, setNftFilter] = useState<string>("monthly");
  const [nftNames, setNftNames] = useState<string[]>([]);
  const [nftValues, setNftValues] = useState<string[]>([]);

  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<string[]>([]);

  const [couponFilter, setCouponFilter] = useState<string>("monthly");
  const [couponNames, setCouponNames] = useState<string[]>([]);
  const [couponValues, setCouponValues] = useState<string[]>([]);

  const handleNftChange = (event: SelectChangeEvent) => {
    setNftFilter(event.target.value as string);
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleCouponChange = (event: SelectChangeEvent) => {
    setCouponFilter(event.target.value as string);
  };

  const nftGraphData = {
    labels: nftNames,
    datasets: [
      {
        label: "Nft",
        data: nftValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphUsersData = {
    labels: userNames,
    datasets: [
      {
        label: "Users",
        data: userValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphCouponData = {
    labels: couponNames,
    datasets: [
      {
        label: "Coupon",
        data: couponValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const getNftData = async () => {
    try {
      const response = await nftMutation({
        filter: nftFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNftNames(Object.keys(response?.data?.totalNfts));
        setNftValues(Object.values(response?.data?.totalNfts));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getUserGraph = async () => {
    try {
      const response = await userMutation({
        filter: userFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserNames(Object.keys(response?.data?.totalUsers));
        setUserValues(Object.values(response?.data?.totalUsers));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getCouponData = async () => {
    try {
      const response = await couponMutation({
        filter: couponFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCouponNames(Object.keys(response?.data?.totalNfts));
        setCouponValues(Object.values(response?.data?.totalNfts));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getUserGraph();
  }, [userFilter]);

  useEffect(() => {
    getNftData();
  }, [nftFilter]);

  useEffect(() => {
    getCouponData();
  }, [couponFilter]);

  return (
    <MainContainer>
      <div className="main_loyout cms_pages">
        <div className="dashboard sec_head">
          <h1>Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item xs={12} md={6}>
            <div className="cards  fs_16 dashGraph_item">
              <div className="sec_head">
                <h2>Total NFT</h2>
                <div className="form_control form_control_bg">
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={nftFilter}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleNftChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="graph">
                <LineChart data={nftGraphData} />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="cards dashGraph_item">
              <div className="sec_head">
                <h2>Total Users</h2>
                <div className="form_control form_control_bg">
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userFilter}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleUserChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="graph">
                <LineChart data={graphUsersData} />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="cards dashGraph_item">
              <div className="sec_head">
                <h2>Coupon Claimed</h2>
                <div className="form_control form_control_bg">
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={couponFilter}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleCouponChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="graph">
                <LineChart data={graphCouponData} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="cards  fs_16 dashGraph_item">
              <div className="sec_head">
                <h2>Challenge Participate</h2>
                <div className="form_control form_control_bg">
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={nftFilter}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleNftChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="graph">
                <LineChart data={nftGraphData} />
              </div>
            </div>
          </Grid>
        </Grid>
        <LogRenderer />
      </div>
    </MainContainer>
  );
};

export default Analytics;

import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Checkbox } from '@mui/material';
import {
    useAddCollectionMutation,
    useUpdateCollectionMutation,
    useLazyGetCollectionByIdQuery,
} from '../../../services/main';
import { useEffect, useState } from 'react';
import { Collection, CommonBody } from '../../../types/General';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { generateEncryptedKeyBody } from '../../../utils/crypto';
import { errorToast, successToast } from '../../../helpers';
import { isNumber, isNumberWithDot, isString } from '../../../utils/validation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

interface Location {
    latitude: number | null;
    longitude: number | null;
}

export default function CollectionCenterForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [updateCollection, { isLoading }] = useUpdateCollectionMutation();
    const [addCollection, addCollectionData] = useAddCollectionMutation();
    const [collectionById] = useLazyGetCollectionByIdQuery();
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [collection, setCollection] = useState<Collection | null>(null);
    const [tradeInStore, setTradeInStore] = useState<boolean>(false);
    // const [collection.recyclingContainer, setRecyclingContainer] = useState<boolean>(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: collection?.name || '',
            address: collection?.address || '',
            longitute: collection?.longitude || '',
            latitute: collection?.latitude || '',
            repairWorkshop: collection?.repairWorkshop || false,
            tradeInStore: collection?.tradeInStore || false,
            recyclingContainer: collection?.recyclingContainer || false,
            acceptableEwaste: collection?.acceptableEwaste,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Name is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),
            address: Yup.string()
                .required('Address is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),
            longitute: Yup.string().required('This field is required'),
            latitute: Yup.string().required('This field is required'),
        }),
        onSubmit: async () => {
            formik.setSubmitting(true);
            let body = {
                name: formik.values.name,
                address: formik.values.address,
                longitude: formik.values.longitute,
                latitude: formik.values.latitute,
                repairWorkshop: formik.values.repairWorkshop,
                tradeInStore: formik.values.tradeInStore,
                recyclingContainer: formik.values.recyclingContainer,
                acceptableEwaste: formik.values.acceptableEwaste
            };

            if (id) {
                try {
                    let encryptedBody = generateEncryptedKeyBody(
                        body
                    ) as CommonBody;
                    const response = await updateCollection({
                        body: encryptedBody,
                        id,
                    }).unwrap();
                    if (response?.statusCode === 200) {
                        successToast('Collection Updated Successfully');
                        formik.resetForm();
                        navigate('/manage-collection-center');
                    } else {
                        errorToast(response?.message || '');
                    }
                } catch (error: any) {
                    errorToast(error?.data?.message || '');
                }
            } else {
                try {
                    let encryptedBody = generateEncryptedKeyBody(
                        body
                    ) as CommonBody;

                    const response = await addCollection(
                        encryptedBody
                    ).unwrap();
                    if (response?.statusCode === 200) {
                        successToast('Collection Added Successfully');
                        formik.resetForm();
                        navigate('/manage-collection-center');
                    } else {
                        errorToast(response?.message || '');
                    }
                } catch (error: any) {
                    errorToast(error?.data?.message || '');
                }
            }
        },
    });

    // const onSelectChange = (type: string) => {
    //   // setRepairWorkshop(true),
    //   // setTradeInStore(false),
    //   // setRecyclingContainer(false)
    //     switch (type) {
    //         case 'repairWorkshop':
    //             return (
    //                 formik.setFieldValuerepairWorkshop(true),
    //                 setTradeInStore(false),
    //                 setRecyclingContainer(false)
    //             );
    //         case 'tradeInStore':
    //             return (
    //                 setTradeInStore(true),
    //                 setRepairWorkshop(false),
    //                 setRecyclingContainer(false)
    //             );
    //         case 'recyclingContainer':
    //             return (
    //               setTradeInStore(true),
    //               setRepairWorkshop(false),
    //             setRecyclingContainer(false)
    //             );
    //         default:
    //             setTradeInStore(false);
    //             setRepairWorkshop(false);
    //             setRecyclingContainer(false);
    //     }
    // };

    const getCollectionByById = async (id: string) => {
        try {
            const response = await collectionById({ id }).unwrap();
            if (response?.statusCode === 200) {
                setCollection(response?.data);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    useEffect(() => {
        if (id && id !== 'add') {
            getCollectionByById(id);
        }
    }, []);

    const getCurrentUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                // Get the address using reverse geocoding
                formik.setFieldValue('longitute', longitude);
                formik.setFieldValue('latitute', latitude);
            });
        } else {
            console.error('Geolocation or Google Map not available');
        }
    };

    return (
        <>
            <div className="fetch_currnt cursor_pointer">
                <h2 onClick={getCurrentUserLocation}>
                    <GpsFixedIcon />
                    Current Location
                </h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="gap_m">
                    <div className="form_control form_control_bg w_50">
                        <label>Name</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Name"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="text_field"
                            name="name"
                            id="name"
                            onChange={(val) => {
                                if (
                                    val.target.value === ' ' ||
                                    val.target.value === '.'
                                ) {
                                } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                    </div>
                    <div className="form_control form_control_bg w_50">
                        <label>Address</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Address"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="text_field"
                            name="address"
                            id="address"
                            onChange={(val) => {
                                if (
                                    val.target.value === ' ' ||
                                    val.target.value === '.'
                                ) {
                                } else {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                            helperText={
                                formik.touched.address && formik.errors.address
                            }
                        />
                    </div>

                    <div className="form_control form_control_bg w_50">
                        <label>Longitude</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Longitude"
                            fullWidth
                            type="text"
                            className="text_field"
                            variant="outlined"
                            name="longitute"
                            id="longitute"
                            onChange={(val) => {
                                if (
                                    val.target.value === ' ' ||
                                    val.target.value === '.'
                                ) {
                                } else if (isNumberWithDot(val.target.value)) {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.longitute}
                            helperText={
                                formik.touched.longitute &&
                                formik.errors.longitute
                            }
                        />
                    </div>
                    <div className="form_control form_control_bg w_50">
                        <label>Latitude</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Latitude"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="text_field"
                            name="latitute"
                            id="latitute"
                            onChange={(val) => {
                                if (
                                    val.target.value === ' ' ||
                                    val.target.value === '.'
                                ) {
                                } else if (isNumberWithDot(val.target.value)) {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.latitute}
                            helperText={
                                formik.touched.latitute &&
                                formik.errors.latitute
                            }
                        />
                    </div>
                    <div className="form_control form_control_bg w_100">
                        <label>Acceptable e-waste</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter acceptable e-waste like 'mobile phone', 'monitor' and etc."
                            fullWidth
                            type="text"
                            className="text_field"
                            variant="outlined"
                            name="acceptableEwaste"
                            id="acceptableEwaste"
                            onChange={(val) => {

                                formik.handleChange(val);

                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.acceptableEwaste}
                        // helperText={formik.touched.longitute && formik.errors.longitute}
                        />
                    </div>
                </div>
                <div className="form_control form_control_bg w_100">
                    <label></label>
                    <Checkbox
                        {...label}
                        onChange={() => {
                            if (formik.values.recyclingContainer) {
                                formik.setFieldValue('recyclingContainer', false);
                            } else {
                                formik.setFieldValue('recyclingContainer', true);
                            }
                        }}
                        checked={formik.values.recyclingContainer ?? false}
                    />Recycling Container
                    <Checkbox
                        {...label}
                        onChange={() => {
                            if (formik.values.repairWorkshop) {
                                formik.setFieldValue('repairWorkshop', false);
                            } else {
                                formik.setFieldValue('repairWorkshop', true);
                            }
                        }}
                        checked={formik.values.repairWorkshop ?? false}
                    />Repair Workshop
                    <Checkbox
                        {...label}
                        onChange={() => {
                            if (formik.values.tradeInStore) {
                                formik.setFieldValue('tradeInStore', false);
                            } else {
                                formik.setFieldValue('tradeInStore', true);
                            }
                        }}
                        checked={formik.values.tradeInStore ?? false}
                    />Trade-In Store
                </div>
                <div className="submit_btn">
                    <button className="btn btn_primary">Submit</button>
                </div>
            </form>
        </>
    );
}

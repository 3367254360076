import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import DashCard from "./feature/dashcard";
import LineChart from "../../components/linechart";
import React, { useEffect, useState } from "react";
import {
  useLazyCouponsClaimedGraphQuery,
  useLazyTotalNftGraphQuery,
} from "../../services/common";
import ChallengerList from "./feature/challengerList";

export default function Dashboard() {
  const [nftMutation] = useLazyTotalNftGraphQuery();
  const [couponMutation] = useLazyCouponsClaimedGraphQuery();

  const [nftFilter, setNftFilter] = useState<string>("monthly");
  const [nftNames, setNftNames] = useState<string[]>([]);
  const [nftValues, setNftValues] = useState<string[]>([]);

  const [couponFilter, setCouponFilter] = useState<string>("monthly");
  const [couponNames, setCouponNames] = useState<string[]>([]);
  const [couponValues, setCouponValues] = useState<string[]>([]);

  const handleNftChange = (event: SelectChangeEvent) => {
    setNftFilter(event.target.value as string);
  };

  const handleCouponChange = (event: SelectChangeEvent) => {
    setCouponFilter(event.target.value as string);
  };

  const nftGraphData = {
    labels: nftNames,
    datasets: [
      {
        label: "Nft",
        data: nftValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const graphCouponData = {
    labels: couponNames,
    datasets: [
      {
        label: "Coupon",
        data: couponValues,
        borderColor: "#204e33",
        backgroundColor: "#204e33",
      },
    ],
  };

  const getNftData = async () => {
    try {
      const response = await nftMutation({
        filter: nftFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNftNames(Object.keys(response?.data?.totalNfts));
        setNftValues(Object.values(response?.data?.totalNfts));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getCouponData = async () => {
    try {
      const response = await couponMutation({
        filter: couponFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCouponNames(Object.keys(response?.data?.totalNfts));
        setCouponValues(Object.values(response?.data?.totalNfts));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getNftData();
  }, [nftFilter]);

  useEffect(() => {
    getCouponData();
  }, [couponFilter]);

  return (
    <MainContainer name="Contact & Support">
      <div className="dashboard_wrp">
        <div className="sec_head fs_22">
          <h2>Dashboard</h2>
        </div>
        <div className="gap_m dash_card_wrp">
          <DashCard />
        </div>
        <div className="graph_card fs_22">
          <Grid container spacing={2} className="dashGraph">
            <Grid item xs={12} md={6}>
              <div className="cards dashGraph_item">
                <div className="sec_head">
                  <h2>Total NFT</h2>
                  <div className="form_control form_control_bg">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nftFilter}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleNftChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="graph">
                  <LineChart data={nftGraphData} />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="cards dashGraph_item">
                <div className="sec_head">
                  <h2>Coupon Claimed</h2>
                  <div className="form_control form_control_bg">
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={couponFilter}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCouponChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="graph">
                  <LineChart data={graphCouponData} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="cards  fs_16 dashGraph_item">
                <div className="sec_head">
                  <h2>Challenge Participate</h2>
                </div>
                {/* <div className="graph">
                  <LineChart data={nftGraphData} />
                </div> */}

                <ChallengerList />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </MainContainer>
  );
}
